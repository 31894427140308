import Link from "next/link";
import Head from "next/head";
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { API_URL, BACKEND_URL } from "./settings";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from "@mui/icons-material/Mail";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { YMInitializer } from "react-yandex-metrika";
import {
  AppBar,
  Avatar, Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container, Divider,
  Drawer,
  IconButton, List, ListItem, ListItemIcon, ListItemText,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import { useTheme } from "@emotion/react";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import useInterval from "./useInterval";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useBasket } from "./basket/functions";
const MainDrawer = dynamic(() => import("../lib/mainDrawer"));

export const myImageLoader = ({ src, width, quality }) => {
  let fname = src.split("#").shift().split("?").shift().split("/").pop();
  //return `https://back.fito.land/img/${width}/${fname}`;
  return `${BACKEND_URL}/img/${width}/${fname}`;
};

export const getDistanceText = (loc_user, distance) => {
  let dist_text = "";
  let ret = "";
  if (loc_user?.location?.formatted_address) {
    if (parseInt(distance) === 0) {
      dist_text = "рядом с вами";
    } else {
      dist_text = Math.round(distance) + " км";
    }
    ret = " (" + dist_text + ")";
  } else {
    ret = "";
  }

  return ret;
};





export default function Layout({
  children,
  title = "",
  image = "",
  description = "",
  backIcon = false,
  backUrl = "/fialki",
  showTopMenu = true
}) {
  const { t, lang } = useTranslation("common");
  const {updateBasket, updateBasketIds, deleteBasketItem, addBasketItem} = useBasket();
  const [value, setValue] = React.useState("");
  const unreadMessages = useSelector((state) => state.unreadMessages);
  const basketItemsIds = useSelector((state) => state.basketItemsIds);
  const [drawerState, setDrawerState] = React.useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const goodToken = useSelector((state) => state.goodToken);
  const user = useSelector((state) => state.user);
  const typeOptions = useSelector((state) => state.typeOptions);
  const theme = useTheme();

  const checkMessages = function(unrMess, user) {
    if (user) {
      axios
      .get(`${API_URL}/im/mynum/`)
      .then((res) => {
        let newMesCount = parseInt(res.data.cnt);
        if (newMesCount !== unrMess) {
          dispatch({
            type: "SETUNREADMESSAGES",
            unreadMessages: newMesCount,
          })
        }
      });
    }
  }

  useInterval(() => {
    checkMessages(unreadMessages, user);
  }, 60000);

  useEffect(() => {
    if (router) setValue("/" + router.pathname.split("/")[1]);
  }, []);


  useEffect(()=>{
    if (user) {
      checkMessages(unreadMessages, user);
      updateBasketIds();
    }
  },[user])


  useEffect(() => {
    if (typeOptions && typeOptions.length === 0) {
      axios
        .options(`${API_URL}/lots/`)
        .then((res) => {
          if (res?.data?.actions?.POST?.type?.choices) {
            let typeOptionsArr = [];
            let typeOptionsSortArr = [];
            res.data.actions.POST.type.choices.forEach((item) => {
              // console.log(item)
              typeOptionsArr.push({
                value: item.value,
                label: item.display_name,
              });
              typeOptionsSortArr.push(item.display_name.charAt(0));
            });
            //setTypeOptions(typeOptionsArr);
            //setTypeOptionsSort(typeOptionsSortArr);
            dispatch({
              type: "SETTYPEOPTIONS",
              typeOptions: typeOptionsArr,
            });
            dispatch({
              type: "SETTYPEOPTIONSSORT",
              typeOptionsSort: typeOptionsSortArr,
            });
          }
        })
        .catch((e) => {
          //console.log(e);
          //console.log("can't get lot options");
        });
    }
  }, [user]);

  useEffect(() => {
    const checkToken = async function (testtoken) {
      axios.defaults.headers.common = { Authorization: `Token ${testtoken}` };
      try {
        //console.log("try checkToken");
        let res1 = await axios.get(`${API_URL}/user/`);
        if (res1 && res1.data) {
          //console.log("good token");
          dispatch({
            type: "SETTOKEN",
            token: testtoken,
          });
          localStorage.setItem("tkn1", testtoken);
          for (let key in res1.data) {
            res1.data[key] = res1.data[key] || "";
          }
          dispatch({
            type: "SETUSER",
            user: res1.data,
          });
          dispatch({
            type: "SETGOODTOKEN",
            goodToken: true,
          });
          return true;
        }
      } catch {
        //console.log("catch in checktoken happened");
        delete axios.defaults.headers.common["Authorization"];
      }
      return false;
    };

    const findToken = async function () {
      //console.log("findToken");
      if (token) {
        let foundRedux = await checkToken(token);
        if (foundRedux) {
          //console.log("redux token found");
          return true;
        }
      }

      let tkn = localStorage.getItem("tkn1");
      if (tkn) {
        let foundLocal = await checkToken(tkn);
        if (foundLocal) {
          //console.log("local token found");
          return true;
        }
      }

      //console.log("check for vk_mini")
      const params = (new URL(window.location)).searchParams;
			const vk_app_id = params.get('vk_app_id');
			const vk_platform = params.get('vk_platform');
			const vk_user_id = params.get('vk_user_id');
			const sign = params.get('sign');
			//console.log("window.location: ", window.location)
			//console.log("params: ", params)
			//console.log("vk_app_id: ", vk_app_id)
			//console.log("vk_platform: ", vk_platform)
			//console.log("sign: ", sign)

			//if (vk_app_id==='7491006') {
			if (vk_app_id==='7512856') {
				let link = '/vkmini/';
				let data = {access_token: window.location.search}
        axios.post(`${API_URL}${link}`, data)
					.then(res => {
						if (res && res.data && res.data.key) {
							//console.log("token set");
							//console.log("res.data.key", res.data.key)
              dispatch({
                type: "SETTOKEN",
                token: res.data.key,
              });
							return true;
						}
					})
					.catch(e => {
						console.log("can't get token by vkmini");
					})




			}

      delete axios.defaults.headers.common["Authorization"];
      dispatch({
        type: "SETTOKEN",
        token: null,
      });
      dispatch({
        type: "SETGOODTOKEN",
        goodToken: false,
      });
      localStorage.removeItem("tkn1");
      dispatch({
        type: "SETUSER",
        user: null,
      });
      //console.log("findToken failed");
      return false;
    };
    //console.log("token: ", token);
    if (!goodToken) {
      //console.log("no good token: ", goodToken);
      findToken().finally(
        dispatch({
          type: "SETAUTHDONE",
          authDone: true,
        })
      );
    }
  }, []);

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={description} />
        <meta property="og:title" content={"FITOLAND: " + title} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />c
      </Head>
      <YMInitializer accounts={[84176305]} options={{ webvisor: true }} />
      <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <header>
          {showTopMenu && <AppBar
            position="fixed"
            style={{
              maxWidth: "960px",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            <Toolbar>
              {backIcon && (
                <IconButton
                  color="inherit"
                  edge="start"
                  sx={{ marginRight: theme.spacing(2) }}
                  aria-label="menu"
                  onClick={() => router.back()}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              {!backIcon && (
                <IconButton
                  color="inherit"
                  edge="start"
                  sx={{ marginRight: theme.spacing(2) }}
                  aria-label="drawer"
                  onClick={() => setDrawerState(!drawerState)}
                >
                  <MenuIcon />
                </IconButton>
              )}

              <Typography
                variant="h6"
                style={{
                  flexGrow: 1,
                  fontSize: "1.1rem",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {title}
              </Typography>
              {!token && (
                <Link href="/auth">
                  <Button color="inherit">{t("login")}</Button>
                </Link>
              )}
              {token &&

                  <Link href="/basket" passHref>
                    <Tooltip title={(basketItemsIds && basketItemsIds.length>0)?t("open_basket"):t("empty_basket")}>
                      <IconButton color='inherit'>
                        <Badge color='warning' badgeContent={basketItemsIds && basketItemsIds.length} >
                          <ShoppingCartIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Link>


              }
              {token &&

                  <Link href="/im" passHref>
                    <Tooltip title={(unreadMessages>0)?t("unread_messages"):t("open_messages")}>
                    <IconButton color='inherit'>
                      <Badge color='warning' badgeContent={unreadMessages} >
                        <MailIcon />
                      </Badge>
                    </IconButton>
                                      </Tooltip>
                  </Link>


              }
              {token && (
                <Link href="/myprofile">
                  <Tooltip title={t("my_profile")}>
                    <Button color="inherit" style={{ marginRight: "-15px" }}>
                      <Avatar
                        alt={user?.username}
                        src={
                          user?.userphotos?.length > 0
                            ? user.userphotos[0].photo_avatar.charAt(0) === "/"
                              ? `${BACKEND_URL}` +
                                user.userphotos[0].photo_avatar
                              : user.userphotos[0].photo_avatar
                            : ""
                        }
                      >
                        {" "}
                        <AccountCircleOutlinedIcon />
                      </Avatar>
                    </Button>
                  </Tooltip>
                </Link>
              )}
            </Toolbar>
          </AppBar>}
          <MainDrawer drawerState={drawerState} setDrawerState={setDrawerState}/>
        </header>
        <Box my={4} py={4} px={1}>
          {children}
        </Box>

        <AppBar
          position="fixed"
          component="footer"
          style={{
            maxWidth: "960px",
            top: "auto",
            bottom: 0,
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        >
          <BottomNavigation
            value={value}
            sx={{ backgroundColor: theme.palette.back.mainBottomBack }}
            onChange={(event, newValue) => {
              setValue(newValue);
              //console.log(newValue);
              event.preventDefault();
              //router.push('\\'+newValue, false,{shallow: true})
              router.push(newValue);
            }}
            showLabels
          >
            <BottomNavigationAction
              label={t("navigation_main")}
              value="/"
              icon={<HomeOutlinedIcon />}
            />
            <BottomNavigationAction
              label={t("navigation_search")}
              value="/plants"
              icon={<ManageSearchIcon />}
            />
            <BottomNavigationAction
              label={t("navigation_wish")}
              value="/mywishlist"
              icon={<StarOutlineOutlinedIcon />}
            />
            <BottomNavigationAction
              label={t("navigation_my")}
              value="/mycollection"
              icon={<CheckCircleOutlineOutlinedIcon />}
            />
            <BottomNavigationAction
              label={t("navigation_people")}
              value="/u"
              icon={<PeopleAltOutlinedIcon />}
            />
          </BottomNavigation>
        </AppBar>
      </Container>
    </div>
  );
}
