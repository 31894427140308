import axios from "axios";
import { API_URL } from "../settings";
import { useDispatch } from "react-redux";

export const useBasket = function(itemId, lot_id) {

  const dispatch = useDispatch();
  const updateBasket = function() {
    try {
      axios.get(`${API_URL}/baskets/`).then((res) => {
        //console.log(res.data);
        if (res.data.results) {
          dispatch({
            type: "SETBASKETS",
            baskets: res.data.results,
          });
          updateBasketIds();
        }
      });
    } catch {
      console.log("error getting baskets");
    }
  };

  const updateBasketIds = function() {
    axios.get(`${API_URL}/basketitems/ids/`).then((res) => {
      let newBasketItemsIds = res.data.ids;
      dispatch({
        type: "SETBASKETITEMSIDS",
        basketItemsIds: newBasketItemsIds,
      });
    });
  };


  const deleteBasketItem = function(itemId) {
    try {
      axios.delete(`${API_URL}/basketitems/${itemId}/`).then((res) => {
        //console.log(res);
        if (res) {
          updateBasket();
        }
      });
    } catch {
      console.log("error deleting ");
    }
  };

  const addBasketItem = async function(lot_id) {
    try {
      let res = await axios.post(`${API_URL}/basketitems/`, {
        lot: lot_id,
      });
      if (res) {
        updateBasketIds();
        //dispatch(showMySnackbar("success", "Добавлено в корзину"));
      }
    } catch {
      console.log("Ошибка добавления в корзину");
      //dispatch(showMySnackbar("error", "Ошибка добавления в корзину"));
    }
  };
  return {updateBasket, updateBasketIds, deleteBasketItem, addBasketItem}

}